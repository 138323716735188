import $ from 'jquery'
import Screen from "./screen";

export default class ObjectFit {
  init() {
    $(function () {
      const screen = new Screen()
      let $objfit = $('img.obj-fit')

      if ($objfit) {
        $(window).on('scroll', function () {
          if (screen.isPC) {
            $objfit.each(function(){
              var imgSrc = $(this).attr('src');
              var fitType = 'cover';
            
              if($(this).data('fit-type')) {
                fitType = $(this).data('fit-type');
              }
            
              $(this).parent().css({ 'background' : 'transparent url("'+imgSrc+'") no-repeat center center/'+fitType, });
              $(this).css('display','none'); 
            });
          } else {
            $objfit.each(function(){
              $(this).parent().removeAttr("style");
              $(this).removeAttr("style");
            });
          }
        })
      }
    })
  }
}
