
import $ from 'jquery'
import 'jquery-inview'

export default class fadeIn {
  init() {
    $(function () {
      let $target = $('.js-fadein')
      let $target_step = $('.js-fadein-step')

      if ($target) {
        $target.each(function() {
          let $this = $(this)

          $this.on('inview', function(event, isInView) {
            if (isInView) {
              $this.addClass('is-show')
            }
          });
        })
      }

      if ($target_step) {
        let count = 0;

        $target_step.each(function() {
          let $this = $(this)

          setTimeout(function(){ 
            $this.addClass('is-show')
          }, count * 1000)
          
          count++;
        })
      }
    })
  }
}
