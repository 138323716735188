import $ from 'jquery'
import 'slick-carousel'

export default class Top {
  init() {
    const $slider = $('.js-slider-top')

    if ($slider.length > 0) {
      $slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true
      })
    }
  }
}

