import $ from 'jquery';
const $window  = $(window);
const target = $('.c-header');

const headerFixed = () => {
  $(window).on('scroll', function () {
    if ($window.scrollLeft() > 0) {
      target.css("left", -$window.scrollLeft());
    }
    else {
      target.css("left", "");
    }
  })
}

export default headerFixed