import $ from 'jquery'

export default class CheckForm {
  init() {
    $(function () {
      if ($('.js-check-form').length) {
        $('.js-check-form').on('click', function () {
          const $checkbox = $(this)
          const $content =  $('.js-check-form-content')

          if(!$checkbox.hasClass('-is-check')) {
            $checkbox.addClass('-is-check')
            $content.fadeIn();
          } else {
            $checkbox.removeClass('-is-check')
            $content.fadeOut();
          }
        });
      }
    })
  }
}
