import $ from 'jquery'
import { breakpointMD } from '../_variables'

export default class CommonAccordian {
  constructor(props) {
    this.accordians = document.querySelectorAll(props.target)
  }

  init() {
    if (this.accordians) {
      $(function () {
        if ($('.js-cmn-accordian').length) {
          $('.js-cmn-accordian').each(function () {
            const $thisModule = $(this)
            const $thisBtnAccd = $thisModule.find('.js-cmn-accordian-btn')
            const $thisCont = $thisModule.find('.js-cmn-accordian-content')

            $thisBtnAccd.off('click')
            $thisBtnAccd.on('click', function () {
              if (!$(this).hasClass('is-open')) {
                $(this).addClass('is-open')
                $thisCont.slideDown(200)
              } else {
                $(this).removeClass('is-open')
                $thisCont.slideUp(200)
              }
            })
          })
        }
      })
    }
  }
}
